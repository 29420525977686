import dayjs, { Dayjs } from 'dayjs';
import produce from 'immer';
import { calculateDateRangeInterval, getDateAsDayjsInstance } from '@utils/date';
import { FiltersProp, SelectedFilterItem, SelectedFilters } from '@constants/data-table';
import { StatusCodes } from '@constants/enum/common';
import { DEFAULT_SORTING_KEY } from '@constants/metering';
import {
  ChartTimeSeriesEntry,
  DateRangeType,
  FULFILLED,
  GET_PIPELINES_USAGE,
  GET_USAGE_HOURS,
  GET_USAGE_LIMITS,
  GET_USAGE_METRICS,
  IAPIPaginationData,
  IDateRange,
  IPipelineUsage,
  IUsageLimits,
  IUsageMetrics,
  PENDING,
  REJECTED,
  RESET_USAGE_DATA,
  SELECT_PIPELINES_USAGE_FILTERS,
  SELECT_PIPELINES_USAGE_SORT_VALUE,
} from '@redux/types/types';

const getDefaultTimeRange = () => {
  // TODO: Convert into dynmaic calculation, when we support date selection
  const startOfMonth = dayjs().utc().startOf('month');
  const endOfMonth = dayjs().utc().endOf('month');
  return {
    type: DateRangeType.THIRTY_DAYS,
    from: startOfMonth,
    to: endOfMonth,
    interval: calculateDateRangeInterval(startOfMonth, endOfMonth),
  };
};

interface IInitialStateProps {
  usageLimits: Partial<IUsageLimits> | null;
  usageMetrics: IUsageMetrics;
  pipelineUsageHours: {
    devHours: ChartTimeSeriesEntry[];
    prodHours: ChartTimeSeriesEntry[];
  };
  pipelineUsageMetrics: IAPIPaginationData<IPipelineUsage[]>;
  fetchingPipelineUsageMetricsStatus: StatusCodes;
  selectedDateRange: IDateRange | null;
  pipelineUsageMetricsFiltersValues: Record<string, FiltersProp | []>;
  selectedPipelineUsageMetricsFilters: SelectedFilters;
  pipelineUsageMetricsSortValue: string;
}

export const initialState: IInitialStateProps = {
  usageLimits: null,
  usageMetrics: {},
  pipelineUsageHours: {
    devHours: [],
    prodHours: [],
  },
  pipelineUsageMetrics: {
    data: [],
    has_more: false,
    total: 0,
  },
  fetchingPipelineUsageMetricsStatus: StatusCodes.IDLE,
  selectedDateRange: getDefaultTimeRange(), // For now, we keep it static to 30 days
  pipelineUsageMetricsFiltersValues: {},
  selectedPipelineUsageMetricsFilters: {},
  pipelineUsageMetricsSortValue: DEFAULT_SORTING_KEY,
};

// TODO: Support different intervals and move to Date.ts util
const createDateListBetween = (start: Dayjs | string, end: Dayjs | string): Dayjs[] => {
  let currentDate = getDateAsDayjsInstance(start);
  const endDate = getDateAsDayjsInstance(end);
  const dateList = [];

  if (!currentDate) return [];

  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
    dateList.push(currentDate);
    currentDate = currentDate.add(1, 'day');
  }

  return dateList;
};

const mergeDateDataWithDefaults = (
  defaultData: Record<
    number,
    {
      timestamp: string;
      development_hours: number;
      production_hours: number;
    }
  >,
  actualData: (IUsageMetrics & { timestamp: string })[],
) => {
  const mergedData = { ...defaultData };
  actualData.forEach((dataPoint) => {
    // TODO: Update to work with different intervals
    const dateKey = getDateAsDayjsInstance(dataPoint.timestamp)!.date();
    if (defaultData[dateKey]) {
      mergedData[dateKey].development_hours = dataPoint.development_hours ?? 0;
      mergedData[dateKey].production_hours = dataPoint.production_hours ?? 0;
    }
  });
  return Object.values(mergedData);
};

const usageReducer = (state = initialState, action: any) => {
  return produce(state, (draft) => {
    const localDraft = draft;
    switch (action.type) {
      case RESET_USAGE_DATA:
        localDraft.selectedDateRange = getDefaultTimeRange();
        break;
      case `${GET_USAGE_LIMITS}/${FULFILLED}`: {
        localDraft.usageLimits = action.payload;
        break;
      }
      case `${GET_USAGE_METRICS}/${FULFILLED}`: {
        // TODO: Add typing for API response
        const {
          pipeline_stats: pipelineStats,
          total_document_storage_units: documentStorageUnits,
          production_pipelines: productionPipelines,
          development_pipelines: developmentPipelines,
        } = action.payload;

        const [totalPipelineStat] = pipelineStats || [];
        const { timestamp: ignore, ...rest } = totalPipelineStat || {};
        localDraft.usageMetrics = {
          ...rest,
          document_storage_units: documentStorageUnits,
          production_pipelines: productionPipelines,
          development_pipelines: developmentPipelines,
        };

        break;
      }
      case `${GET_USAGE_HOURS}/${FULFILLED}`: {
        // TODO: Add typing for API response
        const { data, dateRange } = action.payload;
        const { pipeline_stats: pipelineStats } = data;
        const { from, to } = dateRange;

        const allDatesInRange = createDateListBetween(from, to);
        // TODO: Make it work other intervals
        const initializedDateHours = allDatesInRange.reduce((accumulator, date) => {
          accumulator[date.date()] = {
            timestamp: date.toISOString(),
            development_hours: 0,
            production_hours: 0,
          };
          return accumulator;
        }, {} as Record<number, { timestamp: string; development_hours: number; production_hours: number }>);
        const completeDataSet = mergeDateDataWithDefaults(initializedDateHours, pipelineStats);

        const parseDevData: ChartTimeSeriesEntry[] = completeDataSet.map(
          ({ timestamp, development_hours }: any) => [timestamp, development_hours],
        );
        const parsedProdData: ChartTimeSeriesEntry[] = completeDataSet.map(
          ({ timestamp, production_hours }: any) => [timestamp, production_hours],
        );

        localDraft.pipelineUsageHours = {
          devHours: parseDevData,
          prodHours: parsedProdData,
        };

        break;
      }
      case `${GET_PIPELINES_USAGE}/${PENDING}`:
        localDraft.fetchingPipelineUsageMetricsStatus = StatusCodes.IN_PROGRESS;
        break;
      case `${GET_PIPELINES_USAGE}/${FULFILLED}`:
        localDraft.fetchingPipelineUsageMetricsStatus = StatusCodes.SUCCESS;
        localDraft.pipelineUsageMetrics = action.payload;
        break;
      case `${GET_PIPELINES_USAGE}/${REJECTED}`:
        localDraft.fetchingPipelineUsageMetricsStatus = StatusCodes.ERROR;
        break;

      case SELECT_PIPELINES_USAGE_FILTERS: {
        const { filterKey, items }: { filterKey: string; items: SelectedFilterItem[] } =
          action.payload;
        localDraft.selectedPipelineUsageMetricsFilters[filterKey] = items;
        break;
      }

      case SELECT_PIPELINES_USAGE_SORT_VALUE:
        localDraft.pipelineUsageMetricsSortValue = action.payload;
        break;

      default:
        break;
    }

    return localDraft;
  });
};

export default usageReducer;
